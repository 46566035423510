import Card from 'atoms/Card'
import ImageNext from 'atoms/ImageNext'
import LinkAnchor from 'atoms/LinkAnchor'
import Text from 'atoms/Text'
import React from 'react'

const DynamicCard = ({
    className = '', image, rounded, roundedImage, alt, imageRation, href, flag, children, youtubeLink, imageWidth, imageHeight,
    defaultImageFor = 'imageNotFound', objectFit = 'contain', hoverEffect
}) => {

    const imageProps = (imageWidth && imageHeight) ? { width: imageWidth, height: imageHeight } : { layout: 'fill' }

    const Component = <Card type="regular" className={`${className} ${rounded} transition-all duration-300`}>

        <div className={`${roundedImage || rounded || ''} ${imageRation} relative overflow-hidden bg-gray-300 flex`}>

            {flag && <Text type='bodySmall' color='text-basic-white' fontWeight={600} className='bg-semantic-success_base px-3 py-1 rounded-br-3xl absolute right-0 left-0 inline-block w-fit z-1 line-clamp-1 max-w-[80%]' >{flag}</Text>}

            {
                youtubeLink
                    ? <iframe className='w-full aspect-video' src={youtubeLink} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    : <ImageNext src={image} alt={alt} {...imageProps} defaultImageFor={defaultImageFor} objectFit={objectFit} />
            }

        </div>
        <div className='w-full'>
            {children}
        </div>
    </Card>;

    return (
        href
            ? <LinkAnchor href={href}>{Component}</LinkAnchor>
            : <>{Component}</>
    )
}

export default DynamicCard