import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { getBlogsList } from 'services/blogs.service'
import { BLOG_HEADING_HOME_PAGE } from 'constant/homepage'
import RelatedBlog from 'molecules/RelatedBlog'

const DoctorSection = ({ language = '', load = 10, like = false, mediaType = 1, isBlogPage = false, isHomeBlogs }) => {
    const [blogs, setBlogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        getBlogsList({ page: 1, limit: load, ...(like ? { like } : { featured: true }), language, mediaType })
            .then(response => {
                if (response?.status) {
                    const list = response?.entity?.rows.map(item => ({
                        id: item.id,
                        href: item.href,
                        heading: item.title,
                        description: item.content,
                        blogImage: item?.thumbnail === null ? null : `${process.env.NEXT_PUBLIC_BUCKET_URL}${item?.thumbnail}`,
                        writtenBy: {
                            ...item.writtenBy,
                            image: item.writtenBy?.image === null ? null : `${process.env.NEXT_PUBLIC_BUCKET_URL}${item.writtenBy?.image}`
                        },
                        view: item.views,
                        shares: item.totalShareCount,
                        postedDays: moment(new Date(item.publishedOn)).fromNow(),
                        readTime: item?.readTime
                    }));
                    setBlogs(list);
                }
                setIsLoading(false)
            })
            .catch(error => { console.error(error) });
    }, [language]);

    return (
        <RelatedBlog className={`${isBlogPage ? '' : 'bg-primary1-100'}`} headingClass='pl-4' blogs={blogs} descriptionTitle={isBlogPage ? 'Related Blogs' : BLOG_HEADING_HOME_PAGE} isRelatedBlogs={false} isHomeBlogs={isHomeBlogs} isLoading={isLoading} isBlogPage={isBlogPage} />
    )
}

export default DoctorSection